var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _setup.reports.length > 0
            ? _c(
                "v-col",
                {
                  staticClass: "pbi-report-sidebar pa-2",
                  attrs: { lg: "2", md: "2", sm: "12" },
                },
                [
                  _c("h3", { staticClass: "pa-3" }, [
                    _vm._v("Beschikbare rapportages"),
                  ]),
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass: "pbi-report-sidebar",
                      on: { change: _setup.selectReport },
                      model: {
                        value: _setup.selectedReportId,
                        callback: function ($$v) {
                          _setup.selectedReportId = $$v
                        },
                        expression: "selectedReportId",
                      },
                    },
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "pbi-report-sidebar",
                          attrs: { nav: "" },
                        },
                        _vm._l(_setup.reports, function (itm, index) {
                          return _c(
                            "v-list-item",
                            { key: index, attrs: { text: "", value: itm.id } },
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "form-control",
                                      attrs: { color: "white" },
                                    },
                                    [
                                      _vm._v(_vm._s(itm.name) + " "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _setup.formatDateUtcD(
                                              itm.reportDate
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "app-dashboard-overview",
              attrs: { lg: "10", md: "10", sm: "12" },
            },
            [
              _c(_setup.PowerbiViewer, {
                attrs: {
                  workspaceId: _setup.workspaceId,
                  reportId: _vm.reportId,
                  componentKey: _setup.componentKey,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }